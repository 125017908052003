import { tns } from "tiny-slider"

const slider = tns({
    container: '.slider',
    axis: 'horizontal',
    items: 1,
    autoplay: false,
    controls: false,
    nav: true,
    mouseDrag: true,
    gutter: 10
});

document.querySelector('.slider-prev').onclick = function () {
    slider.goTo('prev');
};

document.querySelector('.slider-next').onclick = function () {
    slider.goTo('next');
};
