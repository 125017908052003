(function () {
    if (document.querySelector("#interactive-map-container")) {
        var mapboxgl = require("mapbox-gl/dist/mapbox-gl.js");

        const mapContainer = document.querySelector("#interactive-map-container");
        const startLng = mapContainer.dataset.startlng;
        const startLat = mapContainer.dataset.startlat;

        const map = new mapboxgl.Map({
            accessToken:
                "pk.eyJ1IjoicHJvamVrdHhhcnR3b3JrIiwiYSI6ImNsNTEwN3BmNjAxMHIzZW10Ym9henhxamoifQ.j3Qd-eBYh81zz5rsnSsXvQ",
            container: "interactive-map",
            style: "mapbox://styles/projektxartwork/cl58oqlwf002q14pdzxf33n4w",
            center: [startLng, startLat], // starting position [lng, lat]
            zoom: 11, // starting zoom
        });

        const addMarker = (uri, title, city, zip, street, streetnumber, lng, lat) => {
            const marker = new mapboxgl.Marker();
            const minPopup = new mapboxgl.Popup();

            minPopup.setHTML(`
            <div class="bg-brand-green p-6">
                    <h3 class="text-lg font-bold text-white mb-2">${title}</h3>
                    <p class="text-sm text-white">
                        ${zip} ${city}<br/>
                        ${street} ${streetnumber}
                    </p>
                    <a
                        href=${uri}
                        class="w-full text-sm text-white font-bold flex items-center justify-between mt-2">
                        <span>Infos lesen</span>
                        <svg viewBox="0 0 28 22.2" xml:space="preserve" class="w-6 h-6 ml-6 fill-white">
                            <path d="M21.6,0l-2.3,1.4l4.9,8.4H0v2.7h24.1l0,0l-4.9,8.4l2.3,1.4l5.6-9.8l0.8-1.3l-0.8-1.4L21.6,0z"/>
                        </svg>
                    </a>
            </div>
        `);
            marker.setPopup(minPopup);
            marker.setLngLat([lng, lat]);
            marker.addTo(map);
        };

        const markers = JSON.parse(mapContainer.dataset.markers);

        const addMarkers = () => {
            Object.values(markers).forEach((marker) => {
                addMarker(
                    marker.uri,
                    marker.title,
                    marker.locationCity,
                    marker.locationZipCode,
                    marker.locationStreet,
                    marker.locationStreetnumber,
                    marker.locationLng,
                    marker.locationLat
                );
            });
        };

        map.on("load", () => {
            addMarkers();
            map.resize();
        });
    }
})();
